<template>
    <div :class="className">
        <img 
            v-if="currentFileUrl" 
            :src="currentFileUrl" 
            class="usercard-img w100" 
            @click.prevent.stop="onImageClick" 
            ref="currentImage"
            loading="lazy"
        >
        <img v-else src="../css/img/placeholder.png" class=" usercard-img w100" @click.prevent.stop="onImageClick" loading="lazy">
        <a  class="bebas uploader-txt w100" v-if="currentFileUrl && editing" @click.prevent="onImageClick">Cambia</a>
        <a class="bebas uploader-txt w100" v-else-if="editing" @click.prevent="onImageClick">scegli</a>
        <input :ref="refName" class="uploader-input" type="file" @change="fileSelected" multiple>
    </div>
</template>
<script>
import {ref} from 'vue'
export default {
    emits:['image-selected', 'image-url-load'],
    props:{
        fileurl:{
            type:String,
            default:null
        },
        editing:{
            type:Boolean,
            default:false
        },
        className:{
            type:String,
            default:'uploader-container w33 flex-container flex-col flex-center-align'
        },
        refName:{
            type:String,
            default:'uploaderInput'
        }
    },
    setup(props, {emit}){
        
        const uploaderInput= ref(null)
        const files = ref(null)
        const currentImage = ref(null)
        const currentFileUrl =  props.fileurl ? ref(props.fileurl): ref(null)

        const onImageClick = () =>{
            //const target = e.target
            if(props.editing){
                //target.parentNode.querySelector('.uploader-input').click()
                uploaderInput.value.click()
            }
            
        }
        const fileSelected = (event) => {
            files.value = event.target.files
            if (files.value  && files.value[0]) {
                const reader = new FileReader()
                reader.addEventListener('load', (e)=>{
                    currentFileUrl.value = e.target.result
                    emit('image-url-load', currentFileUrl.value)
                })
                reader.readAsDataURL(files.value[0])
            }
            emit('image-selected', files.value)
        }
        
        return{
            files,
            fileSelected,
            uploaderInput,
            onImageClick,
            currentFileUrl,
            currentImage
        }
    }
}
</script>
<style lang="postcss">
    
    .uploader-input{
       opacity: 0;
    }
    .usercard-img{
        height: auto;
        cursor: pointer;
    }
    .uploader-txt, .overlay-panel a.uploader-txt{
        line-height: 40px;
        text-align: center;
        background-color: #2cbeff;
        cursor: pointer;
        color:black;
    }
    .uploader-container{
        position: relative;
    }
    
</style>