/**
 * Mutations
 */

const tags_request = (state) => state.loading = true

const tags_success = (state, {data,pagination}) => {
  state.loading = false
  const {CurrentPage} = pagination 
    //prima navigazione
    if(!state.pagination){
      state.pagination = pagination
      state.tags = data
      state.visitedPages.push(CurrentPage)
    } else {
       state.pagination = pagination
      ///se non ho mai visto la paginata -> aggiungo i nuovi dati
      if(!state.visitedPages.find(p => p===CurrentPage)){
        state.visitedPages.push(CurrentPage)
         data.forEach(t =>{
          state.tags.push(t)
        }) 

      }else{
        
        ////altrimenti eseguo l'update sulla porzione di store
        data.forEach((tag, index) =>{
          const toReplace = state.tags.find(t =>t.id === tag.id)
          if(toReplace){
            Object.assign(toReplace, tag)
          } else {
            state.tags[index] = tag
          }
        })
      }
      
      state.pagination = pagination
    }
}
const tag_single_success = (state, {data}) => {
  state.loading = false
  if(!state.tags.find(t => t.id === data.id))
  state.tags.unshift(data)
  state.current = data
}
const tags_error = (state)=>{
  state.loading = false
}

const tags_save_success = (state, {data}) => {
  state.loading = false
  state.tags.unshift(data)
}

const tags_clear =(state)=>{
  state.tags = []
}

const remove_error =(state)=>{
  state.loading = false
}
const remove_success =(state, tag)=>{
  state.loading = false
  const newTags = state.tags.filter(c => c.id !== tag.id)
  state.tags = newTags
}

const do_filter =(state, {data, pagination}) => {
  state.filtered = data
  state.pagination = pagination
}

const empty_filter = (state)=>{
  state.filtered = null
}
const clear_filters =(state)=>{
  state.filtered=null
  state.activeFilters=[]
}

const do_sort =(state, {sorting}) => {
  
  switch(sorting.type){
    case 'Nome asc':
      if(state.filtered){
        state.filtered = state.filtered.sort((a, b) => a.name.localeCompare(b.name))
      }else{
        state.tags = state.tags.sort((a, b) => a.name.localeCompare(b.name))
      }
      
    break
    case 'Nome desc':
      if(state.filtered){
        state.filtered = state.filtered.sort((a, b) => b.name.localeCompare(a.name))
      }else{
        state.tags = state.tags.sort((a, b) => b.name.localeCompare(a.name))
      }
    break
    case 'data creazione asc':
        if(state.filtered){
          state.filtered = state.filtered.sort((a, b) => a.id.localeCompare(b.id))
        }else{
          state.tags = state.tags.sort((a, b) => a.id < b.id)
        }
        
      break
      case 'data creazione desc':
        if(state.filtered){
          state.filtered = state.filtered.sort((a, b) => b.id.localeCompare(a.id))
        }else{
          state.tags = state.tags.sort((a, b) => a.id >= b.id)
        }
      break
  }
  
}

const tags_update_success =(state, {tag}) =>{
  state.loading = false
  const updated = state.tags.find(c=> c.id===tag.id)
  Object.assign(updated, tag)  
}
const savecolumnsizes = (state, sizes)=>{
  state.columnsSizes = sizes
}
export const mutations = {   
    tags_request,
    tags_success,
    tags_error,
    tags_save_success,
    tags_clear,
    remove_error,
    remove_success,
    do_filter,
    do_sort,
    tags_update_success,
    savecolumnsizes,
    tag_single_success,
    empty_filter,
    clear_filters
  }