<template>
    <header v-if="me" class="header" >
        <div class="flex-container flex-col" style="display: flex;flex-direction: row;white-space: nowrap;">
            
               <!--<div class="header-username"  ><span class="label"  >Home: </span>{{page}}</div>-->

              <img id="infotronik_header_logo" src="../css/img/logo.png">
            <nav id="infotronik_header_nav" aria-label="breadcrumb">  
                <ol id="infotronik_header_breadcrumb" class="breadcrumb">
                    <div id="infotronik_header_breadcrumb_item" class="breadcrumb-item"  v-for="rout in routes" :key="rout.id">
                        <router-link :to="rout.path" @click="menu(rout.path)">  {{ rout.name }} </router-link> 
                    </div>
                </ol>
            </nav>



            <div v-if="me?.userScope?.concessionaire" class="header-username-details f12">
                <span class="label">Concessionaria: </span> {{me.userScope.concessionaire.name}}
              
            </div>
        </div>
        <div class="flex-container flex-col f12">
        <div class="header-username"><span class="label">{{me.roles[0]}} : </span>{{me.userName}}</div>

        <!-- <div class="header-credits">&copy; 2021-2024 <a href="" class="url-inflooendo">Inflooendo</a></div>
        <div class="header-credits">Powered by <a href="" class="url-platypus">Platypus</a> ThenBy (Noi)</div>-->

        </div>
    </header>
</template>
<script>
import { ref } from 'vue';

import {inject} from 'vue'
export default {

 //ascolto l'emettitore per il breadcrumb
 created() {    

        ///creo il menu a seconda del mett che arriva
        this.emitter.on('breadcrumb', (back) => {
         
        this.routes = back;
       

        });
        
        ///ascolto il menu
         this.emitter.on('menu', (p) => {      
           this.menu(p)
        });
     

       
    },

    methods: {

    menu(path) { ///menu' laterale gestione pagine


        if (path == "/users"){

            /////emetto nascondendo o meno le colonne////
            this.emitter.emit('mostralista',true); 
            this.emitter.emit('dettagliutente',false);
            this.emitter.emit('breadcrumb',''); 
     
        
        }else if (path.includes("/home")){ 
        
           /////emetto nascondendo o meno le colonne////
           this.emitter.emit('mostralista',true); 
           this.emitter.emit('dettagliutente',false);
           this.emitter.emit('breadcrumb',''); 

        }else if (path.includes("/socials")){  
        
           /////emetto nascondendo o meno le colonne////
           this.emitter.emit('Socialmostralista',false); 
           this.emitter.emit('SocialDettaglisocial',true);
           this.emitter.emit('SocialDettaglisocial2',false);
           this.emitter.emit('breadcrumb',''); 

        }else if (path.includes("/campaigns")){  
     
           /////emetto nascondendo o meno le colonne////
           this.emitter.emit('Campaignsmostralista',true); 
           this.emitter.emit('CampaignsDettaglilista',false);
           //this.emitter.emit('CampaignsDettaglilista2',false);
           this.emitter.emit('breadcrumb',''); 

        }else if (path.includes("/tags")){  
            
           /////emetto nascondendo o meno le colonne////
           this.emitter.emit('Tagsmostralista',true); 
           this.emitter.emit('TagsDettaglisocial',false);
           //this.emitter.emit('CampaignsDettaglilista2',false);
           this.emitter.emit('breadcrumb',''); 

        }else if (path.includes("/userscopes")){  
            
           /////emetto nascondendo o meno le colonne////
           this.emitter.emit('Userscopesmostralista',true); 
           this.emitter.emit('UserscopesDettaglilista',false);
           //this.emitter.emit('CampaignsDettaglilista2',false);
           this.emitter.emit('breadcrumb',''); 

        }


        ////bread vuoto e non nascondo nulla
        else{

           this.emitter.emit('breadcrumb',''); 

        }



  

        
        
     
    }

    },

    setup(){




      const routes = ref([   ]);
    

        const me = inject('me')

        return {
            me,
            //page,
             routes
        }
    }
}
</script>